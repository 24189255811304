import * as React from 'react';
import { Route, Switch } from 'react-router';
import Layout from './components/Layout';
import Register from './components/Register';
import Confirmation from './components/Confirmation';
import InfoParam from './components/infoparam';
import Home from './components/Home';
import NewHome from './components/NewHome';
import Inclusive from './components/Inclusive';
import './Content/CSS/custom-v1.2.css';
import './Content/CSS/isi-v1.2.css';
import './Content/CSS/home-v1.2.css';
import './Content/CSS/footer-v1.1.css';
import './Content/CSS/newhome-v1.2.css';

export default () => (
    <Layout>
        <Switch>
            <Route exact path='/' component={NewHome} />
            <Route exact path='/index.html' component={NewHome} />
            <Route path='/default' component={NewHome} />
            <Route path='/newhome' component={NewHome} />
            <Route path='/home' component={Home} />
            <Route path='/inclusive' component={Inclusive} />
            <Route path='/register' component={Register} />            
            <Route path='/receipt' component={Confirmation} />
            <Route path='/infoparam' component={InfoParam} />
            <Route path='/confirmation/:cc' component={Confirmation} />
            <Route path='/:ac' component={Register} />            
        </Switch>
    </Layout>
);
