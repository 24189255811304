import * as React from 'react';
import { connect } from 'react-redux';
import logo from '../Content/Images/Logo/Capri_logowS4C-v1.1.png';
import TranscendLogo from '../Content/Images/Logo/Transcend_Colored-v1.1.gif';

export class Footer extends React.PureComponent<any, any> {
    //static displayName = Footer.name;       

    render() {
        return (
            <div>
                <footer className="footer">
                    <div className="row">
                        <div className="column1">
                            <div className="footer_capri">
                                <img src={logo} alt="CAPRI | Sign4compliance Comprehensive Medical Meeting Management System" style={{ width: "200px" }} /><br />
                                <div>
                                    <div>
                                        <span>Powered by:</span>
                                        <span className="footer_capr_sign4compliance_span">CapriMeetingManager.com</span>
                                    </div>

                                    <div className="footer_capr_sign4compliance_div">
                                        <span>Sign4Compliance.com</span>
                                    </div>
                                    <div>
                                        <span>Copyright 2024 Blue Grotto Technologies, Inc.</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="column2">
                            <div style={{ textAlign: "center", fontSize: "13px", margin: "0 auto" }}>
                                <a href="https://www.biocryst.com/terms-and-conditions/" target="_blank">Terms of Use</a>
                                &nbsp;|&nbsp;
                                <a href="https://orladeyo.com/cookie-policy/" target="_blank">Cookie Policy</a>
                                &nbsp;|&nbsp;
                                <a href="https://www.biocryst.com/global-privacy-notice/" target="_blank">Privacy Policy</a>&nbsp;&nbsp;&nbsp;
                            </div>
                            <div style={{ textAlign: "center", marginTop: "40px", fontSize: "12px" }}>
                                <div>
                                    <span>BioCryst and ORLADEYO are registered trademarks of BioCryst Pharmaceuticals, Inc.</span>
                                </div>
                                <div>
                                    <span>&copy;2024 BioCryst Pharmaceuticals, Inc. All rights reserved.</span>
                                </div>
                            </div>
                            <br />
                            <div style={{ border: "2px solid #d3d3d3", color: "#333", width: "280px", padding: "5px 10px", fontSize: "13px", display: "none" }}>
                                These programs are intended to provide information about the first-ever oral prophylactic therapy for people living with hereditary angioedema (HAE).
                            </div>
                        </div>

                        <div className="column3">
                            <div className="footer_managedby">
                                <div>
                                    <span style={{ float: "left", fontSize: "12px" }}>Managed by:</span>
                                </div>
                                <div>
                                    <img src={TranscendLogo} alt="Transcend Medical Communications" width="200" height="90" className="transcendLogo" />
                                    <br />
                                    <div style={{ textAlign: "center" }}>
                                        <a href="https://www.transcendmedcom.com/privacy-policy/" target="_blank" style={{ fontSize: "12px" }}>Privacy Policy</a><br /><br />
                                        <span style={{ fontSize: "12px" }} id="spProjectCode">US.ORL.02417 12/24</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </footer>
            </div >
        );
    }
}
export default connect()(Footer);