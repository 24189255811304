import * as React from 'react';


export class ISI extends React.PureComponent<any, any> {

    componentDidMount() {
        var divISI = document.getElementById('fixed-isi')
        if (window.location.pathname == '/') {            
            if (divISI != null)
                divISI.style.top = "calc(100% - 245px)";
        }
        else {
            if (divISI != null) {
                divISI.style.top = "calc(100% - 32px)";
                divISI.classList.add('isi-top-inactive');
            }
        }
    }

    render() {
        return (
            <div>
                <div id="fixed-isi" className="isi idle loaded" onClick={this.handleToggle}>
                    <div className="banner">
                        <div className="container">
                            <div className="content">
                                <h2>
                                    <span className="left-text">WHAT IS ORLADEYO<sup>®</sup> (berotralstat)?</span>
                                    <span className="right-text">IMPORTANT SAFETY INFORMATION</span>
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="content">
                            <div className="must-be-visible">
                                <p>
                                    <h3>WHAT IS ORLADEYO<sup>&reg;</sup> (berotralstat)?</h3>
                             ORLADEYO (or-luh-DAY-oh) is a prescription medicine used to prevent attacks of hereditary angioedema (HAE) in adults and children 12 years of age and older. It is not known if ORLADEYO is safe and effective in children under 12 years of age.
                                </p>
                                <p>
                            It is not known if ORLADEYO is safe and effective to treat an acute HAE attack, therefore ORLADEYO should not be used to treat an acute HAE attack.
                                </p>
                                <p>
                                Do not take more than one capsule of ORLADEYO per day because extra doses can cause heart rhythm problems.
                                </p>
                                <p>
                                <h3>IMPORTANT SAFETY INFORMATION</h3>
                                    <strong>Before taking ORLADEYO, tell your healthcare provider about all of your medical conditions, including if you</strong>
                                </p>
                                <p>
                                    <ul>
                                        <li style={{ fontSize: "14px", fontWeight: "normal" }}>have liver problems or are on kidney dialysis.</li>
                                        <li style={{ fontSize: "14px", fontWeight: "normal" }}>are pregnant or planning to become pregnant. It is not known if ORLADEYO can harm your unborn baby.</li>
                                        <li style={{ fontSize: "14px", fontWeight: "normal" }}>are breastfeeding or plan to breastfeed. It is not known if ORLADEYO passes into your breastmilk. Talk to your healthcare provider about the best way to feed your baby while taking ORLADEYO.</li>
                                    </ul>
                                </p>
                                <p>
                                    <strong>Tell your healthcare provider about all of the medicines you take,</strong> including other medicines for HAE, prescription and over-the-counter medicines, vitamins, and herbal supplements. Taking ORLADEYO with certain other medicines may affect the way other medicines work and other medicines may affect how ORLADEYO works.</p>
                                <p>Know the medicines you take and keep a list of them to show your healthcare provider and pharmacist when you get a new medicine.
                                </p>
                                <p>
                                    <strong>What are the possible side effects of ORLADEYO?</strong> <br /> Taking more than one capsule of ORLADEYO per day may cause serious side effects, including <strong>heart rhythm problems</strong>. A heart rhythm problem called QT prolongation can happen in people who take more than one capsule of ORLADEYO per day. This condition can cause an abnormal heartbeat. Do not take more than one capsule of ORLADEYO per day.</p>
                             <p>The most common side effects of ORLADEYO include abdominal pain, vomiting, diarrhea, back pain, and heartburn. These are not all of the possible side effects of ORLADEYO. For more information, ask your healthcare provider or pharmacist.
                                </p>
                                <p>Talk to your healthcare provider for medical advice about side effects.</p>
                                <p>
                                    <strong>You are encouraged to report side effects of prescription drugs to BioCryst Pharmaceuticals, Inc at 1-833-633-2279 or to the FDA at <a href="https://www.fda.gov/medwatch" rel="noopener" target="_blank">www.fda.gov/medwatch</a> or 1-800-FDA-1088.<p>For more information, please see the <a href="https://www.biocryst.com/wp-content/uploads/ORLADEYO_USPI.pdf" rel="noopener" target="_blank">full Prescribing Information</a> including the <a href="https://www.biocryst.com/wp-content/uploads/ORLADEYO_USPI.pdf#page=13" rel="noopener" target="_blank">Patient Information</a>.</p></strong>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        );
    }

    handleToggle = () => {
        var divISI = document.getElementById('fixed-isi')
        if (divISI != null) {
            if (divISI.classList.contains('isi-top-active')) {
                divISI.classList.remove('isi-top-active');
                divISI.classList.remove('active');
                divISI.classList.add('isi-top-inactive');
            }
            else {
                divISI.classList.add('isi-top-active');
                divISI.classList.add('active');
                divISI.classList.remove('isi-top-inactive');
            }
        }
        var divregisterContainer = document.getElementById('registerContainer');
        if (divregisterContainer != null) {
            divregisterContainer.style.height = 'auto';
        }
        //Home page height
        var divHomeHero = document.getElementById('home-hero')
        if (divHomeHero != null) {
            divHomeHero.style.height = "auto";
            divHomeHero.style.paddingBottom = '0px';
        }
        //Confirmation page height
        var divConfirmationContainer = document.getElementById('divConfirmationContainer')
        if (divConfirmationContainer != null) {
            divConfirmationContainer.style.height = "auto";
        }

    }

};
