import * as React from 'react';
import { connect } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../store';
import * as EventInfoStore from '../store/Register';
import TableTalkTopicImage from '../Content/Images/Program/TABLETALK-v1.0.jpg';
import FamilyTopicImage from '../Content/Images/Program/FAMILY-v1.0.jpg';
import OnlineTopicImage from '../Content/Images/Program/ONLINE-v1.0.jpg';
import ESPProgramTopicImage from '../Content/Images/Program/EMPOWER-v1.0.jpg';
import ProgramDefaultImage from '../Content/Images/Program/WHITE.png';

type EventInfoStoreProps =
    EventInfoStore.RegisterState &
    typeof EventInfoStore.actionCreators &
    RouteComponentProps<{}>;

// Define the TitleImageMap directly in the .tsx file
type TopicImage = {
    imageName: string;
    imageTitle: string;
};

type TopicImageMap = {
    [key: string]: TopicImage;
};

const serialize = (obj: any) => {
    var str = [];
    for (var p in obj)
        if (obj.hasOwnProperty(p)) {
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
    return str.join("&");
}


const TopicImageMap: TopicImageMap = {
    "HAE Table Talks": {
        imageName: TableTalkTopicImage,
        imageTitle: "HAE Table Talks"
    },
    "Family and Caregiver": {
        imageName: FamilyTopicImage,
        imageTitle: "Family and Caregiver"
    },
    "Now is the time to discover a preventative therapy that is effective and convenient": {
        imageName: OnlineTopicImage,
        imageTitle: "Online Program"
    },
    "Starting and Continuing ORLADEYO® with your Patient Services Manager": {
        imageName: ESPProgramTopicImage,
        imageTitle: "EPS Program"
    }
};

export class EventInfoRegistrants extends React.PureComponent<EventInfoStoreProps, any> {
    constructor(props: EventInfoStoreProps) {
        super(props);
        this.state = { EventInfo: '' }
        this.GetEventInfo();
    }

    GetEventInfo() {
        const queryStr = serialize({ sEventId: this.props.eventId, sConfirmatiomCode: this.props.confirmationCode })
        const base_url = 'api/Registration/GetEventinfo';
        const init = { method: 'GET', accept: 'application/json', headers: {} };
        fetch(`${base_url}?${queryStr}`, init).then((response) => response.json())
            .then(data => {
                this.setState({ EventInfo: data }, () => { })
            }).catch(error => {
                console.log(error);
            });
    }

    componentDidUpdate(prevProps: any) {
        if (this.props.eventId !== prevProps.eventId) {
            this.GetEventInfo();
        }
    }

    public render() {
        return (
            <div>
                <div>
                </div>
                <div style={{ display: "flex" }}>
                    <div>
                        <img
                            src={TopicImageMap[this.state.EventInfo.EventTopic] && TopicImageMap[this.state.EventInfo.EventTopic].imageName ? TopicImageMap[this.state.EventInfo.EventTopic].imageName : ProgramDefaultImage}
                            alt={this.state.EventInfo.EventTopic || 'No Image Available'}
                        />
                    </div>
                    <div className="eventinfoValueColumn">
                        <div>
                            <strong>{this.state.EventInfo.EventTopic}</strong>
                        </div>
                        <div>{this.state.EventInfo.EventDate}</div>
                        <div>{this.state.EventInfo.EventTime}</div>
                        <div>{ReactHtmlParser(this.state.EventInfo.Location)}</div>
                        <div>{ReactHtmlParser(this.state.EventInfo.Speaker)}</div>
                    </div>
                </div >
                <div>
                    <input type="hidden" id="_uniqueeventId" name="_uniqueeventId" value={this.state.EventInfo.UniqueIdEvent}></input>
                </div>
            </div>

        )
    }


};
export default connect(
    (state: ApplicationState) => state.register,
    EventInfoStore.actionCreators
)(EventInfoRegistrants as any);