import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import BioCryst from '../Content/Images/Logo/BioCryst_Logo_Reg.png';
import Orladeyo from '../Content/Images/Logo/Orladeyo_Logo-v1.1.png';
import Dismiss from '../Content/Images/icons/icon_dismiss.png';
import Empower from '../Content/Images/Logo/EMPOWER_Logo-v1.1.png';
import { withRouter } from "react-router";
import TagManager from 'react-gtm-module';



const HEADER_TITLE: any = {
    "home": "",
    "newhome": "",
    "cookiepolicy": "",
    "searchevents": "SEARCH",
    "register": "REGISTRATION",
    "receipt": "CONFIRMATION",
    "confirmation": "CONFIRMATION"
}

class Header extends React.PureComponent<RouteComponentProps<{}>, any> {
    constructor(props: any) {
        super(props);
        this.goHome = this.goHome.bind(this);
        this.handleDismissClick = this.handleDismissClick.bind(this);
    }

    componentDidMount() {
        if (process.env.REACT_APP_ENV === 'stage') {
            const tagManagerArgs = {
                gtmId: 'GTM-123456',
                dataLayerName: 'dataLayer'
            }

            TagManager.initialize(tagManagerArgs);
        }
        var divCookie = document.getElementById('divBannerCookieNotification')
        if (document.cookie.indexOf('cookie_notification') > -1) {
            var cookie = document.cookie.substring(document.cookie.indexOf('cookie_notification'));
            var dismissed = cookie.indexOf(';') > -1 ? cookie.substring(cookie.indexOf('=') + 1, cookie.indexOf(';')) : cookie.substring(cookie.indexOf('=') + 1);

            // dismissed = 'Always Show'; // Comment this line to function COrrectly 

            if (dismissed !== 'true') {
                if (divCookie != null)
                    divCookie.style.display = 'none';
            }
        } else {
            if (divCookie != null)
                divCookie.style.display = 'none';
        }

    }

    componentDidUpdate(prevProps: any) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            window.scrollTo(0, 0)
        }
    }

    handleDismissClick() {
        var divCookie = document.getElementById('divBannerCookieNotification');
        document.cookie = 'cookie_notification=true; path=/';
        if (divCookie != null)
            divCookie.style.display = 'none';

    }

    private goHome(e: any) {
        e.preventDefault();
        this.props.history.push("/");
    }

    render() {
        let pathname = this.props.location.pathname;
        let pathnameArr = pathname.split("/");
        let headerTitle = pathnameArr[1] ? HEADER_TITLE[pathnameArr[1]] : "";
        if (headerTitle == undefined)
            headerTitle = "Event Registration";

        return (
            <div>
                <div id="divBannerCookieNotification" style={{ display: "none" }}>
                    <div className="pure-g" id="cookie-container">
                        <div className="pure-u-22-24">                            
                            This website uses cookies to improve user experience. By continuing to use this website, you consent to our use of these cookies. To learn more
                            about how we use and manage cookies, see our <a href="https://www.biocryst.com/global-privacy-notice/" target="_blank">Privacy Policy</a> and <a href="https://orladeyo.com/cookie-policy/" target="_blank">Cookie Policy</a>.
            </div>
                        <div className="pure-u-2-24">
                            <img src={Dismiss} className="pure-img" id="dismiss-notification" alt="Dismiss cookie notification" onClick={this.handleDismissClick} />
                        </div>
                    </div>

                </div>
                <div id="divBannerCovid19Notification" style={{ display:"none" }}>
                    <div className="pure-g" id="covid19-container">
                        <div className="covid-text">
                            <div className="covid-text-1">
                                Due to COVID-19, live events are currently virtual, not in-person.
                            </div>
                        </div>
                    </div>
                </div>
                {headerTitle ?
                    <header >
                        <div className="new-home">
                            <div className='menu'>
                                <div><img src={Orladeyo} alt="Orladeyo" className="img-header-logo" /></div>
                                <div><span className='topnav' style={{color:'#333'}}>{headerTitle}</span></div>
                                <div><a className='topnav' href="#" onClick={this.goHome}>HOME</a></div>
                            </div>
                        </div>
                        <div style={{display:'none'}}>
                            <div style={{ padding: "0px 10px 0px 10px", marginTop: "10px" }}>
                                <div id="header-title" className="header__logo">
                                    <div className="row">
                                        <div className="ImgColumn">
                                            <div className="img_BioCryst">
                                                <img src={BioCryst} alt="BioCryst" className="img-header-logo" />
                                            </div>
                                        </div>
                                        <div className="ImgColumn">
                                            <div className="img_Orladeyo">
                                                <img src={Orladeyo} alt="Orladeyo (berotralstat) capsules 150 mg" className="img-header-logo" />
                                            </div>
                                        </div>
                                        <div className="ImgColumn">
                                            <div className="img_Empower">
                                                <img src={Empower} alt="Empower Patient Services" className="img-header-logo" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ clear: "both" }}></div>
                            </div>
                        </div>
                    </header> : <header></header>
                }
            </div >
        );
    }
}

export default withRouter(Header);