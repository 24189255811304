import * as React from 'react';
import { connect } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';
import Orladeyo from '../Content/Images/Logo/Orladeyo_Logo-v1.1.png';
import BioCryst from '../Content/Images/Logo/BioCryst_Logo-v1.1.png';
import Empower from '../Content/Images/Logo/EMPOWER_Logo-v1.1.png';
import Banner from '../Content/Images/Logo/PatientBannerPlain-v1.1.png';
import ProgramDefaultImage from '../Content/Images/Program/WHITE.png';
import ListIcon1 from '../Content/Images/icons/listicon1-v1.0.png';
import ListIcon2 from '../Content/Images/icons/listicon2-v1.0.png';
import ListIcon3 from '../Content/Images/icons/listicon3-v1.0.png';
import TableTalkTopicImage from '../Content/Images/Program/TABLETALK-v1.0.jpg';
import FamilyTopicImage from '../Content/Images/Program/FAMILY-v1.0.jpg';
import OnlineTopicImage from '../Content/Images/Program/ONLINE-v1.0.jpg';
import ESPProgramTopicImage from '../Content/Images/Program/EMPOWER-v1.0.jpg';
import ProgramRequestImage from '../Content/Images/Program/ProgramRequest-v1.0.png';
import ListIcon4 from '../Content/Images/icons/listicon4-v1.0.png';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { Input } from '@progress/kendo-react-inputs';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../store';
import * as DefaultStore from '../store/Register';
import '@progress/kendo-theme-default/dist/all.css';
import Footer from './Footer';
import { Button, Modal, ModalFooter, ModalBody } from 'reactstrap';

import ProgramRequest from './ProgramRequest';

type DefaultProps =
    DefaultStore.RegisterState &
    typeof DefaultStore.actionCreators &
    RouteComponentProps<{}>;

export interface EventInfo {
    eventId: string;
    program: string;
    location: string;
    speaker: string;
    speakercitystate: string;
    ambassador: string;
    ambassadorstate: string;
    eventmonth: string;
    eventdate: string;
    eventday: string;
    eventyear: string;
    address: string;
    time: string;
    topicimage: string;
    bgColor: string;
    foreColor: string;
    dateBgColor: string;
    topic: string;
}

interface State {
    isMenuOpen: boolean;
    isResponsiveFilterOpen: boolean;
    isFilterContentOpen: boolean;
    programTitle: string;
}


// Define the TitleImageMap directly in the .tsx file
type TopicImage = {
    imageName: string;
    imageTitle: string;
};

type TopicImageMap = {
    [key: string]: TopicImage;
};

const TopicImageMap: TopicImageMap = {
    "HAE Table Talks": {
        imageName: TableTalkTopicImage,
        imageTitle: "HAE Table Talks"
    },
    "Family and Caregiver": {
        imageName: FamilyTopicImage,
        imageTitle: "Family and Caregiver"
    },
    "Now is the time to discover a preventative therapy that is effective and convenient": {
        imageName: OnlineTopicImage,
        imageTitle: "Online Program"
    },
    "Starting and Continuing ORLADEYO® with your Patient Services Manager": {
        imageName: ESPProgramTopicImage,
        imageTitle: "EPS Program"
    }
};

const serialize = (obj: any) => {
    var str = [];
    for (var p in obj)
        if (obj.hasOwnProperty(p)) {
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
    return str.join("&");
}

const loadingPanel = (
    <div className="k-loading-mask">
        <div className="k-loading-image" role='alert' aria-label='Progressing'></div>
        <div className="k-loading-color"></div>
    </div>
);

const scrollToAboutPrograms = (event: any) => {
    event.preventDefault(); // Prevent the default anchor behavior

    // Scroll to the target element with a smooth animation
    const target = document.getElementById('about-programs');
    if (target) {
        target.scrollIntoView({ behavior: 'smooth' });
    }
};

export class NewHome extends React.PureComponent<DefaultProps, any> {
    constructor(props: DefaultProps) {
        super(props);
        this.handleGridDataStateChange = this.handleGridDataStateChange.bind(this);
        this.handleDropdownStateChange = this.handleDropdownStateChange.bind(this);
        this.inputChangeHandler = this.inputChangeHandler.bind(this);
        this.handleSearchEvents = this.handleSearchEvents.bind(this);
        this.registerClick = this.registerClick.bind(this);
        this.handleLiveEvents = this.handleLiveEvents.bind(this);
        this.handleVirtualEvents = this.handleVirtualEvents.bind(this);
        this.handleScrollClick = this.handleScrollClick.bind(this);
        this.handleTogglediv = this.handleTogglediv.bind(this);
        this.ShowRequestForm = this.ShowRequestForm.bind(this);

        this.state = {
            isMenuOpen: false,  // Initialize the menu as closed
            isResponsiveFilterOpen: false,
            isFilterContentOpen: false,
            currentPage: 1,
            pageSize: 3, // You can change the page size if needed
            totalItems: 0,
            data: [], // Array of ProgramItem
            UpcomingPrograms: [],
            radius: [
                { text: '10 Miles', Value: 10 },
                { text: '25 Miles', Value: 25 }
            ],
            inputVal: "",
            value: { text: '10 Miles', Value: 10 },
            EventSearchDates: this.getFormattedDate(new Date(new Date().setMonth(new Date().getMonth()))) + ' - ' + this.getFormattedDate(new Date(new Date().setMonth(new Date().getMonth() + 4))),
            FromdefaultValue: new Date(new Date().setMonth(new Date().getMonth())),
            ToDefaultValue: new Date(new Date().setMonth(new Date().getMonth() + 4)),
            LiveEvent: 'live',
            VirtualEvent: 'virtual',
            gridDataState: {
                sort: [
                    { field: "EvtName", dir: "" }
                ],
                page: { skip: 0, take: 10 }
            },
            ShowLoader: true,
            displayProgramRequestForm: false,
            SubmitMessage: false,
        };
    }

    toggleMenu = () => {
        this.setState((prevState: State) => ({
            isMenuOpen: !prevState.isMenuOpen
        }));
    };

    toggleResponsiveFilter = () => {
        this.setState((prevState: State) => ({
            isResponsiveFilterOpen: !prevState.isResponsiveFilterOpen
        }));
    };

    toggleFilterContent = () => {
        this.setState((prevState: State) => ({
            isFilterContentOpen: !prevState.isFilterContentOpen
        }));
    };

    componentDidMount() {

        this.GetSearchEvents();

        //Zipcode
        var varZipcode = document.getElementById("txtZipcode");
        if (varZipcode != null) {
            var attZipcode = document.createAttribute("inputmode");
            attZipcode.value = "numeric";
            varZipcode.setAttributeNode(attZipcode);
        }

        //Adjust page height as per ISI mode
        var divISI = document.getElementById('fixed-isi');
        if (divISI != null) {
            divISI.style.top = "calc(100% - 245px)";
            if (divISI.classList.contains('isi-top-inactive')) {
                var divHomeHero = document.getElementById('home-hero')
                if (divHomeHero != null) {
                    divHomeHero.style.height = "auto";
                    divHomeHero.style.paddingBottom = '0px';
                }
            }
        }
    }

    componentDidUpdate(prevProps: any, prevState: any) {
        if (this.state.LiveEvent !== prevState.LiveEvent || this.state.VirtualEvent !== prevState.VirtualEvent) {
            this.GetSearchEvents();
            this.setState({ inputVal: '' });
        }
    }

    handleScrollClick = () => {
        var input = document.getElementById('divAboutThePrograms')
        if (input != null)
            window.scrollTo(0, input.offsetTop);
    }

    handleTogglediv = () => {
        var searchFilters = document.getElementById('divSearchFilters')
        if (searchFilters != null) {

            if (searchFilters.style.display == 'none') {
                searchFilters.style.display = 'block';
                var searchEventsToggle = document.getElementById('spanSearchEventsToggle')
                if (searchEventsToggle != null)
                    searchEventsToggle.innerHTML = ("Close Search<i className='up'></i>");
            }
            else {
                var searchFilters = document.getElementById('divSearchFilters')
                if (searchFilters != null)
                    searchFilters.style.display = 'none';
                var searchEventsToggle = document.getElementById('spanSearchEventsToggle')
                if (searchEventsToggle != null)
                    searchEventsToggle.innerHTML = ("Advanced Search<i className='down'></i>");
            }
        }
    }

    handleLiveEvents = (e: any) => {
        this.setState({ ShowLoader: true });
        var spanSearchLocation = document.getElementById('spanSearchLocation');
        var tdziptext = document.getElementById('tdziptext');
        var tdradius = document.getElementById('tdradius');
        if (e.target.checked) {
            this.setState({ LiveEvent: e.target.value });
            if (spanSearchLocation != null && tdziptext != null && tdradius != null) {
                spanSearchLocation.style.display = '';
                tdziptext.style.display = '';
                tdradius.style.display = '';
            }
        }
        else {
            this.setState({ LiveEvent: '' });
            if (spanSearchLocation != null && tdziptext != null && tdradius != null) {
                spanSearchLocation.style.display = 'none';
                tdziptext.style.display = 'none';
                tdradius.style.display = 'none';
            }
        }

    }

    handleVirtualEvents = (e: any) => {
        this.setState({ ShowLoader: true });
        if (e.target.checked) {
            this.setState({ VirtualEvent: e.target.value });
        }
        else {
            this.setState({ VirtualEvent: "" });
        }
    }

    registerClick = (e: any) => {
        this.props.setEventID(e);
        this.props.history.push("/register");
    }

    handleGridDataStateChange = (e: any) => {
        this.setState({ gridDataState: e.data });
    }

    handleDropdownStateChange = (event: any) => {
        this.setState({ value: event.target.value });
    }

    inputChangeHandler = (event: any) => {
        this.setState({ inputVal: event.target.value });
    }

    handleSearchEvents = (event: any) => {
        event.preventDefault();
        this.setState({ ShowLoader: true });
        this.GetSearchEvents();
        this.setState({ EventSearchDates: this.getFormattedDate(this.state.FromdefaultValue) + ' - ' + this.getFormattedDate(this.state.ToDefaultValue) })
    }

    GetSearchEvents() {
        const queryStr = serialize({
            sEventTypeLive: this.state.LiveEvent,
            sEventTypeVirtual: this.state.VirtualEvent,
            sZipcode: this.state.inputVal,
            sRadius: this.state.value.Value,
            sFromDate: this.state.FromdefaultValue.toJSON(),
            sToDate: this.state.ToDefaultValue.toJSON()
        });

        const base_url = 'api/Registration/GetSearchEvents';
        const init = { method: 'GET', accept: 'application/json', headers: {} };

        fetch(`${base_url}?${queryStr}`, init)
            .then((response) => response.json())
            .then((data) => {
                console.log(data);  // Log the data to verify the structure
                this.setState({ UpcomingPrograms: data });
                this.setState({ ShowLoader: false });
            })
            .catch((error) => {
                this.setState({ ShowLoader: false });
                console.log(error);
            });
    }

    getFormattedDate(date: any) {
        let year = date.getFullYear();
        let month = (1 + date.getMonth()).toString().padStart(1, '0');
        let day = date.getDate().toString().padStart(1, '0');

        return month + '/' + day + '/' + year;
    }

    ShowRequestForm() {
        this.setState({ displayProgramRequestForm: true });
    }


    public render() {
        let contents = this.renderCreateForm();

        return <div>
            {contents}
            {this.state.displayProgramRequestForm && <ProgramRequest onProgramRequestFormClose={(value: any) => this.setState({ displayProgramRequestForm: value })} onSubmit={(value: any) => this.setState({ SubmitMessage: value })} />}
            <Modal isOpen={this.state.SubmitMessage}>
                <ModalBody>
                    <span>Thank you! An email has been sent. We will be in touch.</span>
                </ModalBody>
                <ModalFooter>
                    <Button color='secondary' onClick={() => this.setState({ SubmitMessage: false })}>Close</Button>
                </ModalFooter>
            </Modal>
        </div>;
    }

    private renderCreateForm() {
        const { currentPage, pageSize, data, totalItems } = this.state;
        // Calculate the total number of pages
        const totalPages = Math.ceil(totalItems / pageSize);

        const minDate = new Date("1/1/2000");

        return (
            <div className="new-home">
                <div className='menu'>
                    <div><img src={Orladeyo} alt="Orladeyo" className="img-header-logo" /></div>

                    {/* Hamburger icon (button) */}
                    <button className="hamburger" onClick={this.toggleMenu}>
                        &#9776; {/* Unicode for hamburger icon */}
                    </button>

                    {/* Navigation links, shown when isMenuOpen is true */}
                    <nav className={this.state.isMenuOpen ? 'topnav responsive' : 'topnav'}>
                        <a href="https://espanol.orladeyoeventspatient.com/" target='_blank'>ESPAÑOL</a>
                        <a href="https://www.biocryst.com/wp-content/uploads/ORLADEYO_USPI.pdf#page=13" target='_blank'>PRESCRIBING INFORMATION</a>
                        <a href="https://orladeyo.com/#page-isi" target='_blank'>IMPORTANT SAFETY INFORMATION</a>
                        <a href="#about-programs" onClick={scrollToAboutPrograms}>ABOUT PROGRAMS</a>
                    </nav>
                </div>
                <div className="shareaholic-canvas shareaholic-canvas-home" data-app="share_buttons" data-app-id="33194209"></div>
                <div>
                    <div className="banner-container">
                        <div className="banner-image">
                            <img
                                src={Banner} alt="Banner"
                            />
                            <div className="overlay-text overlay-text-1">
                                <h2>Join us for a FREE educational program to learn about a preventative therapy that is</h2>
                            </div>
                            <div className="overlay-text overlay-text-2">
                                <p>Capsule not actual size</p>
                            </div>
                            <div className="overlay-text overlay-text-3">
                                <p>ORLADEYO is the only targeted preventative therapy for hereditary angioedema (HAE) to combine proven attack prevention AND convenient oral administration.</p>
                            </div>
                            <div className="overlay-text overlay-text-4">
                                <p>Kharri, switched to ORLADEYO in 2020</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="content">
                        <div className="top-row">
                            <div className='top-row-left-column'>
                                <div onClick={this.toggleFilterContent} className="filter-bar">
                                    <button className="filter-button" type="submit"> {this.state.isFilterContentOpen ? 'HIDE FILTER' : 'SHOW FILTER'} </button>
                                </div>
                                <div className={`filter-content ${this.state.isFilterContentOpen ? 'open' : 'closed'}`}>
                                    <div className="row">
                                        <div style={{ padding: '0px 20px' }}>
                                            <div className="row">
                                                <div>
                                                    <div className="divProgramcheckbox">
                                                        <span className="filter-sub">PROGRAM TYPES</span>
                                                        <div className="divProgramcheckbox">
                                                            <div className="checkbox-container">
                                                                <div>
                                                                    <input type="checkbox" id="liveprogram" name="liveprogram" aria-label="liveprogram" value="live" defaultChecked onChange={e => this.handleLiveEvents(e)} />
                                                                    <label className="labelcheckboxes">Live Program</label>
                                                                </div>
                                                                <div>
                                                                    <input type="checkbox" id="virtualprogram" name="virtualprogram" aria-label="virtualprogram" value="virtual" defaultChecked onChange={e => this.handleVirtualEvents(e)} />
                                                                    <label className="labelcheckboxes">Virtual Program</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div>
                                                    <div id="divSearchFilters" style={{ display: "block", minHeight: "0px" }}>
                                                        <form onSubmit={this.handleSearchEvents}>
                                                            <div className="filter-container">
                                                                <div>
                                                                    <div>
                                                                        <span className="filter-sub">DATES</span>
                                                                    </div>
                                                                    <div className="colDates">
                                                                        <div className="dateTable">
                                                                            <div>
                                                                                <div className="filters_ColumnTitles"><span className="fieldlabel">From:</span> </div>
                                                                                <DatePicker
                                                                                    name="FromDate"
                                                                                    format={"M/d/yyyy"}
                                                                                    width="110px"
                                                                                    min={minDate}
                                                                                    valid={(this.state.FromdefaultValue !== null && this.state.ToDefaultValue !== null)
                                                                                        && (this.state.FromdefaultValue.getTime() < this.state.ToDefaultValue.getTime()) && this.state.FromdefaultValue > minDate}
                                                                                    validationMessage={(this.state.FromdefaultValue === null || this.state.FromdefaultValue < minDate) ? 'Please enter valid date' : 'From date cannot be after To date!'}
                                                                                    defaultValue={this.state.FromdefaultValue}
                                                                                    onChange={(e) => this.setState({ FromdefaultValue: e.target.value })}
                                                                                />
                                                                            </div>
                                                                            <div style={{ marginLeft: "3px" }}>
                                                                                <div className="filters_ColumnTitles"><span className="fieldlabel">To:</span> </div>
                                                                                <DatePicker
                                                                                    name="ToDate"
                                                                                    format={"M/d/yyyy"}
                                                                                    width="110px"
                                                                                    min={minDate}
                                                                                    valid={(this.state.FromdefaultValue !== null && this.state.ToDefaultValue !== null)
                                                                                        && (this.state.FromdefaultValue.getTime() < this.state.ToDefaultValue.getTime()) && this.state.ToDefaultValue > minDate}
                                                                                    validationMessage={this.state.ToDefaultValue === null || this.state.ToDefaultValue < minDate ? 'Please enter valid date' : 'To date cannot be before From date!'}
                                                                                    defaultValue={this.state.ToDefaultValue}
                                                                                    onChange={(e) => this.setState({ ToDefaultValue: e.target.value })}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="filter-location-container">
                                                                    <div>
                                                                        <div style={{paddingTop: '7px'}}>
                                                                            <span className="filter-sub">LOCATION</span>
                                                                        </div>
                                                                        <div>
                                                                            <div className="dateTable">
                                                                                <div id="tdziptext" style={{ marginTop: "10px" }}>
                                                                                    <Input
                                                                                        type="number"
                                                                                        placeholder="Zip Code"
                                                                                        aria-label="zipcode"
                                                                                        id="txtZipcode"
                                                                                        value={this.state.inputVal}
                                                                                        maxLength={5}
                                                                                        valid={(this.state.inputVal !== null && (this.state.inputVal.length === 0 || this.state.inputVal.length === 5))}
                                                                                        validationMessage={'Please enter valid zip code'}
                                                                                        onChange={this.inputChangeHandler}
                                                                                        style={{ border: "3px solid #83A0B0", width: "110px", fontWeight: 500 }}
                                                                                    />
                                                                                </div>
                                                                                <div id="tdradius" className='tdradius'>
                                                                                    <div className="filters_ColumnTitles"><span className="fieldlabel">Radius:</span> </div>
                                                                                    <DropDownList
                                                                                        data={this.state.radius}
                                                                                        textField="text"
                                                                                        style={{ width: "110px" }}
                                                                                        dataItemKey="Value"
                                                                                        value={this.state.value}
                                                                                        onChange={this.handleDropdownStateChange}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div>
                                                                        <button className="search-button" type="submit">Search</button>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </form>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="top-row-right-column">
                                <div className='filter-find-program-text'>
                                    <span>
                                        Not finding a program in your area? Request one and we will come to you!
                                    </span>
                                </div>
                                <div className='filter-find-program'>
                                    {/*        <a className='filter-request-program' href="javascript:void(0)" onClick={this.ShowRequestForm}>Request a Program in Your Area</a>*/}
                                    <img src={ProgramRequestImage} alt='Reqeust Program' onClick={this.ShowRequestForm} style={{ cursor: 'pointer' }}></img>
                                    <span className="request-program-overlay-text" onClick={this.ShowRequestForm}>Request a Program in Your Area</span>
                                </div>
                            </div>
                        </div>

                        <div className="bottom-content">
                            <div className="right-column">
                                <div className="right-header">
                                    <h1>
                                        {this.state.ShowLoader
                                            ? "Loading..."
                                            : this.state.UpcomingPrograms && this.state.UpcomingPrograms.length > 0
                                                ? "UPCOMING PROGRAMS"
                                                : null}
                                    </h1>
                                    {/* Conditionally render divMessge when the Upcoming Programs header is visible */}
                                    {this.state.UpcomingPrograms && this.state.UpcomingPrograms.length > 0 && (
                                        <div className='compliance-message'>
                                            <span>
                                                Please note that all attendees must be 12 years of age or older, as well as an HAE patient or caregiver, to attend this program.
                                            </span>
                                        </div>
                                    )}
                                </div>
                                <div className="programs-row">
                                    {this.state.ShowLoader ? (
                                        <div className="loading-panel">
                                            {/* Display the loader here */}
                                            {loadingPanel} {/* Ensure that 'loadingPanel' is the content you want to show as the loading state */}
                                        </div>
                                    ) : (
                                        this.state.UpcomingPrograms && this.state.UpcomingPrograms.length > 0 ? (
                                            this.state.UpcomingPrograms.map((item: EventInfo) => (
                                                <div key={item.eventId} className="program-card">
                                                    <div className="program-card-image">
                                                        <img
                                                            src={TopicImageMap[item.topic] && TopicImageMap[item.topic].imageName ? TopicImageMap[item.topic].imageName : ProgramDefaultImage}
                                                            alt={item.topicimage || 'No Image Available'}
                                                        />

                                                        <div className="program-title-overlay">
                                                            {TopicImageMap[item.topic] ? (
                                                                <p className="program-title">{ReactHtmlParser(TopicImageMap[item.topic].imageTitle)}</p>
                                                            ) : (
                                                                <p className="program-title">No Title Available</p> 
                                                            )}
                                                        </div>
                                                        <div className="date-overlay">
                                                            <div className="date-month">{ReactHtmlParser(item.eventmonth)}</div>
                                                            <div className="date-day">{ReactHtmlParser(item.eventday)}</div>
                                                            <div className="date-year" style={{display: 'none' }}>{ReactHtmlParser(item.eventyear)}</div>
                                                        </div>
                                                    </div>
                                                    <div className="program-card-details" style={{ paddingTop: '20px' }}>
                                                        <p className="program-details" style={{ fontWeight: 'bold', display:'none' }} >{ReactHtmlParser(item.program)}</p>
                                                        <p className="program-details">{ReactHtmlParser(item.location)}</p>
                                                        <p className="program-details">{ReactHtmlParser(item.speaker)}</p>
                                                        <p className="program-details">{ReactHtmlParser(item.time)}</p>
                                                        <p className="program-details" style={{ display: 'none' }}>{ReactHtmlParser(item.topic)}</p>
                                                    </div>
                                                    <div className="program-card-footer">
                                                        {item.eventId ? (
                                                            <button className="register-button" onClick={(e) => this.registerClick(item.eventId)}>
                                                                Register
                                                            </button>
                                                        ) : (
                                                            <button disabled className="register-button" style={{ backgroundColor: "#ccc", borderColor: "#ccc" }}>
                                                                Register
                                                            </button>
                                                        )}
                                                    </div>
                                                </div>
                                            ))
                                        ) : (

                                            <div className="right-header" style={{ overflow: 'hidden' }}>
                                                <h1>UPCOMING PROGRAMS</h1>
                                                <span className='no-upcoming-programs'>Unfortunately, there are no programs in your selected date range at this time. Please check back for program location updates or for questions, please call 1-877-547-5640 or email <a href="mailto:Support@ORLADEYOeventspatient.com">Support@ORLADEYOeventspatient.com</a></span>
                                            </div>
                                        )
                                    )}
                                </div>
                                <div id="about-programs" className="about-programs">
                                    <h1>ABOUT PROGRAMS</h1>
                                </div>
                                <div>
                                    <span style={{ textAlign: 'left' }}>
                                        This live educational program series on ORLADEYO<sup>®</sup> (berotralstat) offers people living within HAE and their caregivers the opportunity to:
                                    </span>
                                </div>
                                <div className="bullet-items">
                                    <div className="capsule-item">
                                        <img src={ListIcon1} alt="icon" />
                                        <span className='capsule-item-1'>Identify factors to think about in finding the right preventative therapy for you</span>
                                    </div>
                                    <div className="capsule-item">
                                        <img src={ListIcon2} alt="icon" />
                                        <span className='capsule-item-2'>Learn about ORLADEYO<sup>®</sup> (berotralstat), the only targeted preventative therapy for people living with hereditary angioedema (HAE) to combine proven attack prevention AND convenient oral administration</span>
                                    </div>
                                    <div className="capsule-item">
                                        <img src={ListIcon3} alt="icon" />
                                        <span className='capsule-item-3'>Hear from someone living with HAE, obtain 1-on-1 support, gain access and starting therapy with ORLADEYO</span>
                                    </div>
                                    <div className="capsule-item">
                                        <img src={ListIcon4} alt="icon" />
                                        <span className='capsule-item-4'>Have your questions answered during a Q&A session</span>
                                    </div>
                                </div>
                                <div className="moreInfo">
                                    <p> Looking for more information? Visit the ORLADEYO<sup>®</sup> website</p>
                                </div>
                                <div className="cta">
                                    <a href="https://www.orladeyo.com" target='_blank'>ORLADEYO.com</a>
                                </div>
                                <div className="images-row">
                                    <div className="left-image">
                                        <img src={BioCryst} alt="BioCryst" className="img-header-logo" />
                                    </div>
                                    <div className="center-text">
                                        For questions or to register for a program, please contact:1-877-547-5640 or <a href="mailto:Support@ORLADEYOeventspatient.com">Support@ORLADEYOeventspatient.com</a>
                                    </div>
                                    <div className="right-image">
                                        <img src={Empower} alt="Empower" className="img-header-logo" />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div><Footer /></div>
            </div>
        );
    }
}
export default connect(
    (state: ApplicationState) => state.register,
    DefaultStore.actionCreators
)(NewHome as any);
